.tailormed-radio {
  .ant-radio-button-wrapper {
    border: 1px solid #d0d5dd !important;
    color: #1d2939 !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #d0d5dd !important;
    box-shadow: none !important;
  }
  .ant-radio-button-wrapper-checked {
    background-color: #f9fafb !important;
    box-shadow: none !important;
  }
}

@primary-color: #1570EF;@font-family: Open Sans, sans-serif;@border-color-base: #D0D5DD;@border-radius-base: 4px;@table-header-bg: transparent;@table-header-color: #667085;@table-header-sort-bg: transparent;@table-body-sort-bg: transparent;@table-header-sort-active-bg: transparent;@layout-body-background: transparent;@process-icon-color: #ECFDF3;@process-icon-text-color: #0e9384;@steps-nav-active-color: #0e9384;@wait-tail-color: #ECFDF3;@finish-tail-color: #0e9384;@wait-icon-color: #0e9384;@finish-icon-color: #0e9384;@radio-button-color: #1570EF;@radio-button-checked-bg: #F5FAFF;@select-dropdown-bg: #F9FAFB;@select-item-selected-bg: #F5FAFF;@select-item-active-bg: #F2F4F7;@popover-padding-horizontal: 0px;