.print {
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}
.assistance-card {
  margin-top: 20px;
}

.assistance-card .section-title {
  font-size: 15px;
}

.assistance-card .section-body {
  background-color: #f9fafb;
  padding: 10px;
  margin-bottom: 10px;
}

.page-break {
  margin-top: 1rem;
  margin-bottom: 2.5mm;
  display: block;
  -moz-column-break-after: always;
  break-after: always;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
