div.ant-modal-content > div.ant-modal-header {
  background: #eff8ff;
}

.new-style-header-popup > div.ant-modal-content > div.ant-modal-header > div.ant-modal-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #081131;
}

.new-style-header-popup > div.ant-modal-content > button.ant-modal-close > span.ant-modal-close-x {
  color: #475467;
}

div.ant-modal-content > button.ant-modal-close,
div.ant-modal-content > button.ant-modal-close > span.ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-confirm .ant-modal-body {
  padding: 24px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 24px;
  color: #101828;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  font-weight: 400;
  font-size: 14px;
  color: #667085;
}
.ant-modal-confirm-centered .ant-modal-content {
  border-radius: 12px;
  width: 520px;
  background: #ffffff;
  box-shadow: 0px 19px 76px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
div.ant-modal-content
  > div.ant-modal-body
  > div.ant-modal-confirm-body-wrapper
  > div.ant-modal-confirm-btns
  > button.ant-btn {
  padding: 10px 16px;
  gap: 8px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #344054;
}
.alert
  > div.ant-modal-content
  > div.ant-modal-body
  > div.ant-modal-confirm-body-wrapper
  > div.ant-modal-confirm-btns
  > button.ant-btn-primary {
  padding: 10px 16px;
  gap: 8px;
  height: 44px;
  background: #d92d20;
  border: 1px solid #d92d20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.alert > div.ant-modal-content > div.ant-modal-body > div.ant-modal-confirm-body-wrapper > div.ant-modal-confirm-btns {
  margin-top: 40px;
}
div.ant-modal-content
  > div.ant-modal-body
  > div.ant-modal-confirm-body-wrapper
  > div.ant-modal-confirm-btns
  > button.ant-btn-primary {
  padding: 10px 16px;
  gap: 8px;
  height: 44px;
  background: #1570ef;
  border: 1px solid #1570ef;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

div.ant-modal-content > div.ant-modal-body > div.ant-modal-confirm-body-wrapper > div.ant-modal-confirm-btns {
  margin-top: 40px;
}

div.ant-modal-mask {
  background: #00000099;
}
