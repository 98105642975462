// Converted from Sass, seems based on https://codepen.io/si/pen/rQQPap

.dashboard-loader-container {
  position: relative;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: #acacac !important;
  font-size: 20px;
  font-weight: 300;
}

.dashboard-loader-container {
  @number-of-molecules: 8;
  @molecule-diameter: 1.2vh;
  @width-multiplier: 5;
  @animation-duration: 1100ms;

  @molecule-color: #80ddcd;
  @connection-color: #2b56a3;

  .dna {
    display: flex;
    flex-flow: row nowrap;
    width: (@molecule-diameter * @width-multiplier);
    position: relative;
    transform: rotate(90deg);

    .column {
      display: flex;
      flex-flow: column;
      width: @molecule-diameter;

      &:nth-of-type(3) {
        position: absolute;
        left: 0;

        .dot {
          background-color: @molecule-color;
          animation-direction: alternate-reverse, normal;
        }
      }

      &:nth-of-type(1) {
        .dot {
          background-color: @molecule-color;
          animation-direction: alternate, normal;
        }
      }

      .dot {
        display: block;
        width: @molecule-diameter;
        height: @molecule-diameter;
        margin-bottom: @molecule-diameter;
        border-radius: 50%;
        background-color: @molecule-color;
        animation-name: molecule-animation;
        animation-duration: @animation-duration;
      }

      .dash {
        @height: @molecule-diameter / 3;

        display: block;
        height: @height;
        width: ((@molecule-diameter * @width-multiplier) - (@molecule-diameter * 3));
        margin-top: @height;
        margin-bottom: (@molecule-diameter + @height);
        margin-left: (@molecule-diameter / 2);
        border-radius: (@height / 2);
        background-color: @connection-color;
        animation-name: connection-animation;
        animation-duration: (@animation-duration / 2);
        animation-direction: alternate;
      }

      .dot,
      .dash {
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        each(range(@number-of-molecules), {
          &:nth-of-type(@{value}) {
            animation-delay: -((@animation-duration / @number-of-molecules) * @value);
          }
        })
      }
    }

    @keyframes molecule-animation {
      from {
        transform: translate3d(0, 0, 0);
      }
      to {
        transform: translate3d(((@molecule-diameter * @width-multiplier) - @molecule-diameter), 0, 0);
      }
    }

    @keyframes connection-animation {
      from {
        transform: scale3d(1, 1, 1);
      }
      to {
        transform: scale3d(0, 1, 1);
      }
    }
  }
}

@primary-color: #1570EF;@font-family: Open Sans, sans-serif;@border-color-base: #D0D5DD;@border-radius-base: 4px;@table-header-bg: transparent;@table-header-color: #667085;@table-header-sort-bg: transparent;@table-body-sort-bg: transparent;@table-header-sort-active-bg: transparent;@layout-body-background: transparent;@process-icon-color: #ECFDF3;@process-icon-text-color: #0e9384;@steps-nav-active-color: #0e9384;@wait-tail-color: #ECFDF3;@finish-tail-color: #0e9384;@wait-icon-color: #0e9384;@finish-icon-color: #0e9384;@radio-button-color: #1570EF;@radio-button-checked-bg: #F5FAFF;@select-dropdown-bg: #F9FAFB;@select-item-selected-bg: #F5FAFF;@select-item-active-bg: #F2F4F7;@popover-padding-horizontal: 0px;