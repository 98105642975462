.fieldParentDiv {
    position: relative;
    padding: 16px;
    width: 100%;
}

.hoverElement {
    position: absolute;
    top: 8px;
    left: -10px;
    display: none;
}

.fieldParentDiv:hover .hoverElement {
    display: block;
}

.hoverElement .ant-space-vertical {
    align-items: center;
}

.ant-btn-group-vertical .ant-btn {
    padding: 4px 8px;
    font-size: 12px;
}

.hoverElement .ant-btn[disabled] {
    background-color: #f0f0f0;
    color: #000;
    border: none;
}