div.ant-message > div > div.ant-message-notice > div.ant-message-notice-content {
  font-weight: 600;
  font-size: 14px;
  color: #101828;
}

div.ant-message > div > div.ant-message-notice > div.ant-message-notice-content:hover {
  opacity: 1;
  cursor: pointer;
}

div.ant-message > div > div.ant-message-notice.error-message > div.ant-message-notice-content {
  color: black;
  border-radius: 8px;
  height: 64px;
}

div.ant-message > div > div.ant-message-notice > div.ant-message-notice-content > div.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.ant-message
  > div
  > div.ant-message-notice
  > div.ant-message-notice-content
  > div.ant-message-custom-content
  > span.anticon {
  display: none;
}
