.tailormed-popover .ant-popover-inner-content {
  color: #333333;
  padding: 0;
}

.tailormed-popover .ant-popover-arrow-content::before {
  background: #eff8ff;
}

.tailormed-popover #notifications_popover,
.tailormed-popover #tasks_popover,
.tailormed-popover #patient_tasks_popover,
.tailormed-popover #patient_notes_popover,
.tailormed-popover #patient_attachments_popover {
  border: 1px solid #d0d5dd;
  border-radius: 12px;

  .ant-popover-inner-content {
    width: 100%;
    margin-top: 10px;
  }
  .ant-popover-title {
    background: #eff8ff;
    border-radius: 12px 12px 0px 0px;
    border-color: #d0d5dd;
    border-bottom: 1px solid #d0d5dd;

    div {
      display: flex;
      justify-content: space-between;
      padding: 0px 16px;

      .ant-typography {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        color: #081131;
        padding: 7px 0px;
      }

      .anticon {
        color: #475467;
      }
    }
  }
}

.tailormed-popover #notifications_popover,
.tailormed-popover #tasks_popover {
  width: 678px;
}

.tailormed-popover #notifications_popover {
  height: calc(100vh - 140px);
  .ant-list {
    height: calc(100vh - 310px);
  }
}

.tailormed-popover #tasks_popover {
  height: calc(100vh - 140px);
  .ant-list {
    height: calc(100vh - 260px);
  }
}

.tailormed-popover #patient_notes_popover,
.tailormed-popover #patient_attachments_popover {
  width: 482px;
}

.tailormed-popover #patient_tasks_popover {
  width: 600px;
}
